'use client';
import { useKeyPress } from '@volvo-cars/react-utils';
import { useTracker } from '@volvo-cars/tracking';
import React, { useContext, useCallback } from 'react';

const KeyboardNavigator: React.FC<
  React.PropsWithChildren<{
    nextSlide: SpringCarouselProvider['nextSlide'];
    previousSlide: SpringCarouselProvider['previousSlide'];
    emitEvent: (type: SpringCarouselEvent['type']) => void;
    enableKeyboardTracker?: boolean;
  }>
> = ({ nextSlide, previousSlide, emitEvent, enableKeyboardTracker }) => {
  const tracker = useTracker();
  const next = useCallback(() => {
    nextSlide();
    emitEvent('click-arrow-next');
    if (enableKeyboardTracker) {
      tracker.interaction({
        eventAction: 'arrow|next',
        eventLabel: 'next',
      });
    }
  }, [nextSlide, emitEvent, enableKeyboardTracker, tracker]);
  const previous = useCallback(() => {
    previousSlide();
    emitEvent('click-arrow-previous');
    if (enableKeyboardTracker) {
      tracker.interaction({
        eventAction: 'arrow|previous',
        eventLabel: 'previous',
      });
    }
  }, [previousSlide, emitEvent, enableKeyboardTracker, tracker]);
  useKeyPress('ArrowRight', next);
  useKeyPress('ArrowLeft', previous);
  return null;
};

type ProgressUnsub = () => void;
type ProgressCallback = (event: SlideEvent) => void;
type SlideEvent = {
  value: {
    _progress: number;
    _down: number;
    _dragOffset: number;
    _total: number;
    _dragAxis: 'none' | 'horizontal' | 'vertical';
  };
};

export type SpringCarouselEvent = {
  type:
    | 'swipe-pane'
    | 'click-arrow-previous'
    | 'click-arrow-next'
    | 'tab-target-item';
  current: number;
  previous: number;
};

type SetCurrentCallback = (state: {
  current: number;
  previous: number;
}) => void;

type SetTotalCallback = (state: { total: number }) => void;

type ContextState = {
  /**
   * Previous item in carousel.
   */
  previous: number;

  /**
   * Current item in carousel.
   */
  current: number;

  /**
   * Total number of items in carousel.
   */
  total: number;

  /**
   * Adds `1` to current, with a max of `total`.
   */
  next: (callback?: SetCurrentCallback) => void;

  /**
   * Subtracts `1` to current, with a min of `1`.
   */
  prev: (callback?: SetCurrentCallback) => void;

  /**
   * Sets current item in carousel, starting from `1`.
   */
  setCurrent: (slide: number, callback?: SetCurrentCallback) => void;

  /**
   * @deprecated please use `setCurrent` instead.
   */
  set: (slide: number) => void;

  /**
   * Sets total items in carousel.
   */
  setTotal: (total: number, callback?: SetTotalCallback) => void;

  /**
   * Sets total items in carousel.
   */
  onProgress: (callback: ProgressCallback) => ProgressUnsub;

  /**
   * Add an event listener for progress in the carousel.
   */
  emitProgress: (event: SlideEvent) => void;

  /**
   * Emits an event to the `onChange` event listener on the
   * SpringCarouselProvider
   */
  emitEvent: (type: SpringCarouselEvent['type']) => void;
  disabled?: boolean;
};

export const SpringCarouselContext = React.createContext<ContextState>({
  previous: 0,
  current: 0,
  total: 0,
  next: () => {},
  prev: () => {},
  set: () => {},
  setCurrent: () => {},
  setTotal: () => {},
  onProgress: () => () => {},
  emitProgress: () => {},
  emitEvent: () => {},
  disabled: false,
});

type Props = {
  /** Enable keyboard navigation by using the left and right arrow keys. */
  enableKeyboardNavigation?: boolean;
  onChange?: (event: SpringCarouselEvent) => void;
  disabled?: boolean;
  initialCurrent?: number;
  enableKeyboardTracker?: boolean;
  children?: React.ReactNode;
};

export class SpringCarouselProvider extends React.Component<Props> {
  mounted = false;
  progressSubscriptions: { id: number; fn: ProgressCallback }[] = [];

  state = {
    previous: 1,
    current: this.props.initialCurrent || 1,
    total: 0,
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
    this.progressSubscriptions.length = 0;
  }

  emitEvent: ContextState['emitEvent'] = (
    type: SpringCarouselEvent['type'],
  ) => {
    const { current, previous } = this.state;

    typeof this.props?.onChange === 'function' &&
      this.props.onChange({ type, current, previous });
  };

  onProgress: ContextState['onProgress'] = (callback) => {
    const id = incrementId();

    this.progressSubscriptions.push({ id, fn: callback });

    return () => {
      for (let i = 0; i < this.progressSubscriptions.length; i++) {
        if (this.progressSubscriptions[i].id === id) {
          this.progressSubscriptions.splice(i, 1);
          return;
        }
      }
    };
  };

  emitProgress: ContextState['emitProgress'] = (event: SlideEvent) => {
    this.progressSubscriptions.forEach(
      (callback) => typeof callback.fn === 'function' && callback.fn(event),
    );
  };

  setTotal: ContextState['setTotal'] = (total, callback) => {
    this.setState({ total }, () => {
      typeof callback === 'function' && callback({ total });
    });
  };

  setCurrent: ContextState['setCurrent'] = (slide, callback) => {
    const previous = this.state.current;
    const current = Math.max(1, Math.min(slide, this.state.total));

    this.setState({ current, previous }, () => {
      typeof callback === 'function' && callback({ current, previous });
    });
  };

  /**
   * @deprecated carousel.set method is deprecated, please switch to carousel.setCurrent
   */
  set: ContextState['set'] = (slide) => {
    console.warn(
      '[SpringCarousel] carousel.set method is deprecated, please switch to carousel.setCurrent',
    );
    typeof console.trace === 'function' && console.trace();

    this.setCurrent(slide);
  };

  nextSlide: ContextState['next'] = (callback) =>
    this.setCurrent(this.state.current + 1, callback);

  previousSlide: ContextState['prev'] = (callback) =>
    this.setCurrent(this.state.current - 1, callback);

  render() {
    const context: ContextState = {
      previous: this.state.previous,
      current: this.state.current,
      total: this.state.total,
      next: this.nextSlide,
      prev: this.previousSlide,
      set: this.set,
      setCurrent: this.setCurrent,
      setTotal: this.setTotal,
      onProgress: this.onProgress,
      emitProgress: this.emitProgress,
      emitEvent: this.emitEvent,
      disabled: this.props.disabled,
    };

    return (
      <SpringCarouselContext.Provider value={context}>
        {this.props.enableKeyboardNavigation && (
          <KeyboardNavigator
            nextSlide={this.nextSlide}
            previousSlide={this.previousSlide}
            emitEvent={this.emitEvent}
            enableKeyboardTracker={this.props.enableKeyboardTracker}
          />
        )}
        {this.props.children}
      </SpringCarouselContext.Provider>
    );
  }
}

type SpringCarouselHook = () => ContextState;

export const useSpringCarousel: SpringCarouselHook = () =>
  useContext(SpringCarouselContext);

/**
 * Simple function that returns an incremental number i++
 */
const incrementId = (
  (i) => () =>
    i++
)(0);
