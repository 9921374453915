import type { SlidingGalleryProps as SharedSlidingGalleryProps } from '@vcc-package/galleries';
import { BreakpointsProvider } from '@vcc-www/hooks';
import { SlidingGalleryLayout } from './sliding-gallery.layout';

export type SlidingGalleryProps = {
  items: SharedSlidingGalleryProps['mediaItems'];
  initialCurrent?: number;
};

export function SlidingGallery({
  items,
  initialCurrent = 2,
}: SlidingGalleryProps) {
  return (
    <BreakpointsProvider>
      <SlidingGalleryLayout items={items} initialCurrent={initialCurrent} />
    </BreakpointsProvider>
  );
}
