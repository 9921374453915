'use client';
import { useDrag } from '@use-gesture/react';
import { useMemoDeep } from '@vcc-www/hooks/useMemoDeep';
import throttle from 'lodash/throttle';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
  KeyboardEvent,
} from 'react';
import { animated, useSpring } from 'react-spring';
import { Block, Flex, useTheme } from 'vcc-ui';
import { useSpringCarousel } from '.';
import {
  MediaQueryObject,
  convertToMediaQueries,
  createMediaQueryStyleBlocks,
  getValueByMediaQuery,
} from './SpringCarousel.helpers';

const AnimatedDiv = animated.div;

/*
 * TODO
 *
 * - [BUG]  Fix compensation for offset on progress caused by alignment
 * - [BUG]  Even slide count plus align left prevents slide to last item
 */

// Should extend keys of ThemeBreakpoints as soon as it doesn't contain dynamic
// props anymore, if that happens.
type MediaQueries<T> = { default: T } & Partial<{
  onlyS: T;
  untilM: T;
  fromM: T;
  onlyM: T;
  untilL: T;
  fromL: T;
  onlyL: T;
  untilXL: T;
  fromXL: T;
  onlyXL: T;
}>;

type Alignment =
  | 'left'
  | 'left-cover'
  | 'center'
  | 'center-cover'
  | 'right'
  | 'right-cover';

export type SpringCarouselPaneProps = {
  /**
   * Item width in percentage, `0 - 1`. When using media queries the values from
   * the matched queries cascades, just like in CSS.
   *
   * The value from `default` will always match.
   *
   * Will look for matching VCC UI breakpoints, such as `fromM` and `onlyS`.
   *
   * Defaults to `1`.
   *
   * Examples:
   *
   * ```js
   * // Example as number - Item width for all cases
   * itemWidth={0.5}
   *
   * // Example as object - Item width for 1-4 columns using media queries.
   * itemWidth={{
   *
   *  // Default will always match
   *  default: 1,
   *
   *  // Shorthands from VCC UI theme breakpoints
   *  onlyM: 1 / 2,
   *  fromL: 1 / 3,
   *
   *  // A regular media query
   *  '@media (min-width: 2560px)': 1 / 4
   * }}
   * ```
   */
  itemWidth?: number | MediaQueries<number>;

  /**
   * Item spacing in pixels. When using media queries the values from the
   * matched queries cascades, just like in CSS.
   *
   * The value from `default` will always match.
   *
   * Will look for matching VCC UI breakpoints, such as `fromM` and `onlyS`.
   *
   * Defaults to `0`.
   *
   * Examples:
   *
   * ```js
   * // Example as number - Item spacing for all cases
   * itemSpacing={16}
   *
   * // Example as object - Item spacing for 3 breakpoints using media queries.
   * itemSpacing={{
   *
   *  // Default will always match
   *  default: 8,
   *
   *  // Shorthands from VCC UI theme breakpoints
   *  fromM: 16,
   *
   *  // A regular media query
   *  '@media (min-width: 2560px)': 24
   * }}
   * ```
   */
  itemSpacing?: number | MediaQueries<number>;

  /**
   * Specify if the drag event should add drag velocity when calculating the
   * next index of the pane. When using media queries the values from the
   * matched queries cascades, just like in CSS.
   *
   * The value from `default` will always match.
   *
   * Will look for matching VCC UI breakpoints, such as `fromM` and `onlyS`.
   *
   * Defaults to `true`.
   *
   * Examples:
   *
   * ```js
   * // Example as number - Velocity for all cases
   * enableVelocity={true}
   *
   * // Example as object - Velocity for 3 breakpoints using media queries.
   * enableVelocity={{
   *
   *  // Default will always match
   *  default: true,
   *
   *  // Shorthands from VCC UI theme breakpoints
   *  fromM: false,
   *
   *  // A regular media query
   *  '@media (min-width: 2560px)': true
   * }}
   * ```
   */
  enableVelocity?: boolean | MediaQueries<boolean>;

  /**
   * Specify slide alignment. When using media queries the values from the
   * matched queries cascades, just like in CSS.
   *
   * The value from `default` will always match.
   *
   * Will look for matching VCC UI breakpoints, such as `fromM` and `onlyS`.
   *
   * Defaults to `left`.
   *
   * Examples:
   *
   * ```js
   * // Example as number - Alignment for all cases
   * alignment="center"
   *
   * // Example as object - Alignment for 3 breakpoints using media queries.
   * alignment={{
   *
   *  // Default will always match
   *  default: 'center',
   *
   *  // Shorthands from VCC UI theme breakpoints
   *  fromM: 'left',
   *
   *  // A regular media query
   *  '@media (min-width: 2560px)': 'right'
   * }}
   * ```
   */
  alignment?: Alignment | MediaQueries<Alignment>;

  /**
   * Specify if the component should support RTL markets internally.
   *
   * This component conflicts with some automated RTL support, e.g. via PostCSS.
   * Setting this to 'false' ensures no conflict with such automated RTL conversion.
   *
   * Defaults to true.
   */
  supportsRtl?: boolean;

  /**
   * Specify if the slides should be aligned to center in case of no overflowed items
   *
   *
   * Defaults to `false`.
   *
   * Examples:
   *
   * ```js
   * // Example as number - Alignment for all cases
   * center={true}
   *
   * ```
   */
  center?: boolean;

  /**
   * Handler for clicking on a carousel item.
   * Will call function with current slide number (not zero-indexed).
   * Function will be skipped if isDragging is true.
   *
   * Example
   * ```js
   * onClick={clickHandlerFunction}
   * ```
   */
  onClick?: (currentSlide: number) => void;

  /**
   * Adds role="list" to wrapping div
   * Also adds role="listitem" to children
   */
  useRole?: boolean;

  /**
   * Allows custom 'data-testid' to wrapping div
   */
  testID?: string;

  /**
   * Hide spring carousel overflow
   */
  isOverflowHidden?: boolean;
};

export const SpringCarouselPane: React.FC<
  React.PropsWithChildren<SpringCarouselPaneProps>
> = ({
  children,
  itemWidth = 1,
  itemSpacing = 0,
  enableVelocity = true,
  alignment = 'left',
  supportsRtl = true,
  center = false,
  onClick,
  useRole = false,
  testID = 'springCarousel',
  isOverflowHidden = false,
}) => {
  const { breakpoints, direction } = useTheme();
  const carousel = useSpringCarousel();
  const { setCurrent, setTotal, current, disabled, total } = carousel;
  const [disableAnimation, setDisableAnimation] = useState(true);
  const slideWrapper = useRef<HTMLInputElement>(null);
  const panWrapper = useRef<HTMLInputElement>(null);
  const dragRef = useRef<HTMLInputElement>(null);
  const isDragging = useRef(false);
  const disableClicking = useRef(false);
  const itemCount = React.Children.count(children);
  const isRtl = supportsRtl && direction === 'rtl';

  const itemWidthSet = useMemoDeep(
    () => convertToMediaQueries(itemWidth, breakpoints),
    [itemWidth, breakpoints],
  );
  const itemSpacingSet = useMemoDeep(
    () => convertToMediaQueries(itemSpacing, breakpoints),
    [itemSpacing, breakpoints],
  );
  const enableVelocitySet: MediaQueryObject[] = useMemoDeep(
    () => convertToMediaQueries(enableVelocity, breakpoints),
    [enableVelocity, breakpoints],
  );
  const alignmentSet: MediaQueryObject[] = useMemoDeep(
    () => convertToMediaQueries(alignment, breakpoints),
    [alignment, breakpoints],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateCarousel = useCallback(
    throttle(() => {
      const snapPoints = getSnapPoints({
        itemWidth: getValueByMediaQuery(itemWidthSet),
        alignment: getValueByMediaQuery(alignmentSet),
        itemCount,
      });
      const slideCount = snapPoints.length;
      setTotal(slideCount, () => {
        setCurrent(Math.min(current, slideCount));
      });
    }, 500),
    [itemWidthSet, alignmentSet, itemCount, setCurrent, setTotal, current],
  );

  const [spring, setSpring] = useSpring<{
    _dragOffset: number;
    _total: number;
    _progress: number;
    _down: number;
    _dragAxis: 'none' | 'horizontal' | 'vertical';
    onChange: (event: any) => void;
  }>(() => ({
    _dragOffset: 0,
    _total: 0,
    _progress: 0,
    _down: 0,
    _dragAxis: 'none' as const,
    onChange: (event: any) => carousel.emitProgress(event),
  }));

  // Watch resize of window to update slide count.
  useEffect(() => {
    updateCarousel();
    window.addEventListener('resize', updateCarousel);
    return () => window.removeEventListener('resize', updateCarousel);
  }, [updateCarousel]);

  // Prevents initial rendering to animate sliding.
  useEffect(() => {
    const timeout = setTimeout(() => {
      disableAnimation && setDisableAnimation(false);
    });
    return () => clearTimeout(timeout);
  }, [disableAnimation]);

  // Update carousel spring state with last updated index.
  if (slideWrapper.current) {
    const snapPoints = getSnapPoints({
      itemWidth: getValueByMediaQuery(itemWidthSet),
      alignment: getValueByMediaQuery(alignmentSet),
      itemCount,
    });

    // Ensure both current slide and snap points are updated.
    if (snapPoints.length >= carousel.current) {
      setSpring.start({
        _dragOffset: snapPoints[carousel.current - 1],
        _progress: Math.max(
          (carousel.current - 1) / (carousel.total - 1) || 0,
          0,
        ),
        immediate: disableAnimation,
      });
    }
  }

  // Disable links when dragging.
  useEffect(() => {
    const element = panWrapper.current;

    if (!element) return;

    const clickHandler = (event: Event) => {
      if (disableClicking.current) {
        event.preventDefault();
        event.stopPropagation();
      }
    };

    element.addEventListener('click', clickHandler);

    return () => {
      element.removeEventListener('click', clickHandler);
    };
  }, []);

  // prevent vertical scroll of touch devices after horizontal drag has begun
  useEffect(() => {
    const preventScrolling = (e: Event) => {
      if (isDragging.current) e.preventDefault();
    };
    document.addEventListener('touchmove', preventScrolling, {
      passive: false,
    });
    return () => document.removeEventListener('touchmove', preventScrolling);
  }, [isDragging]);

  let dragClickPosIndex = 0;
  let dragSlideWidth = 0;
  let dragEnableVelocity = 0;
  let dragMinOffset = 0;
  let dragMaxOffset = 0;
  let dragSnapPoints: number[] = [];

  useDrag(
    ({
      first,
      last,
      velocity,
      direction: [dx],
      movement: [mx, my],
      touches,
      type,
      cancel,
    }) => {
      // Disable the drag when the carousel items does not exceed screen width
      if (total <= 1) {
        return;
      }
      // Cancels all keyboard activated drag events
      if (type === 'keydown') {
        cancel();
        return;
      }

      // Threshold pixel value for detecting horizontal or vertical drag.
      const AXIS_DETECTION_THRESHOLD = 2;

      // Adapt move value and direction value after RTL or LTR.
      const moveX = isRtl ? -mx : mx;
      const directionX = isRtl ? -dx : dx;

      // On first drag event we set the initial click pos and get the slideWidth.
      if (first) {
        dragEnableVelocity = getValueByMediaQuery(enableVelocitySet);
        dragClickPosIndex = spring._dragOffset.get();
        dragSlideWidth =
          slideWrapper.current?.getBoundingClientRect().width || 0;

        const snapPoints = getSnapPoints({
          itemWidth: getValueByMediaQuery(itemWidthSet),
          alignment: getValueByMediaQuery(alignmentSet),
          itemCount,
        });

        dragMinOffset = snapPoints[0];
        dragMaxOffset = snapPoints[snapPoints.length - 1];
        dragSnapPoints = snapPoints;
      }

      const dragOffset = getDragPosition({
        dragMovePos: moveX,
        dragStartPos: dragClickPosIndex,
        itemWidthPx: dragSlideWidth,
        minOffset: dragMinOffset,
        maxOffset: dragMaxOffset,
      });

      if (
        dragOffset === -Infinity ||
        dragOffset === Infinity ||
        isNaN(dragOffset)
      ) {
        carousel.setCurrent(carousel.current);
        return;
      }

      const progress = getProgress({
        dragOffset,
        snapPoints: dragSnapPoints,
      });

      // Will "pick up" the spring in the current state.
      if (first) {
        isDragging.current = false;
        disableClicking.current = false;
        setSpring.start({
          _dragOffset: dragOffset,
          _progress: progress,
          _down: 1,
          immediate: true,
        });
      }

      // dragAxis is initially "none" on drag and we use the first dragging
      // pixels to determine if the drag is horizontal or vertical.
      if (spring._dragAxis.get() === 'none') {
        if (Math.abs(mx) > AXIS_DETECTION_THRESHOLD || touches === 0) {
          isDragging.current = true;
          disableClicking.current = true;
          setSpring.start({
            _dragOffset: dragOffset,
            _dragAxis: 'horizontal',
            _down: 1,
            immediate: true,
          });
        } else if (Math.abs(my) > AXIS_DETECTION_THRESHOLD) {
          setSpring.start({
            _dragAxis: 'vertical',
            immediate: true,
          });
        }
      }

      // On horizontal drag we update the index and progress of the spring.
      if (spring._dragAxis.get() === 'horizontal' && !last) {
        setSpring.start({
          _dragOffset: dragOffset,
          _progress: progress,
          _dragAxis: 'horizontal',
          _down: 1,
          immediate: true,
        });
      }

      // On last drag event we snap the slide to the closest index.
      if (last) {
        const v = dragEnableVelocity
          ? velocity[1] * (1 - getValueByMediaQuery(itemWidthSet) + 0.2)
          : 0;
        let dragOffsetWithVelocity = dragOffset;

        if (directionX < 0) {
          dragOffsetWithVelocity = dragOffset + v;
        } else if (directionX > 0) {
          dragOffsetWithVelocity = dragOffset - v;
        }

        const currentSlide = getDropPoint({
          dragOffset: dragOffsetWithVelocity,
          direction: directionX,
          snapPoints: dragSnapPoints,
        });

        carousel.setCurrent(
          spring._dragAxis.get() === 'horizontal'
            ? currentSlide
            : carousel.current,
          () => {
            carousel.current !== currentSlide &&
              carousel.emitEvent('swipe-pane');
          },
        );

        // reset
        isDragging.current = false;
        setSpring.start({
          _dragAxis: 'none',
          immediate: true,
        });

        // delays click disabling so that it updates after potential clicks
        setTimeout(() => {
          disableClicking.current = false;
        });
      }
    },
    {
      target: dragRef,
      enabled: !disabled,
      axis: 'lock',
    },
  );

  return (
    // width 100% necessary for IE, touchAction necessary for third party scripts: https://use-gesture.netlify.app/docs/extras/#touch-action
    <AnimatedDiv
      style={{
        width: '100%',
        touchAction: 'pan-y',
        overflow: isOverflowHidden ? 'hidden' : 'visible',
      }}
      ref={dragRef}
      onClick={() => {
        isDragging.current === false &&
          typeof onClick === 'function' &&
          onClick(current);
      }}
    >
      <Flex
        ref={panWrapper}
        data-testid={testID}
        extend={{
          touchAction: 'pan-y',
          ...createMediaQueryStyleBlocks(itemSpacingSet, (margin) => ({
            margin: `0 -${margin / 2}px`,
          })),
        }}
      >
        <Flex
          ref={slideWrapper}
          extend={{
            userSelect: 'none',
            ...createMediaQueryStyleBlocks(itemWidthSet, (width) => ({
              ...(center && width * React.Children.count(children) < 1
                ? {
                    fromL: {
                      marginLeft:
                        React.Children.count(children) > 1
                          ? `${
                              (width * 100) /
                              (React.Children.count(children) - 1)
                            }%`
                          : 0,
                    },
                    alignSelf:
                      React.Children.count(children) === 1
                        ? 'center'
                        : 'flex-start',
                  }
                : {}),
              width: `${width * 100}%`,
            })),
          }}
        >
          <AnimatedDiv
            style={{
              display: 'flex',
              flexWrap: 'nowrap',
              flexDirection: 'row',
              transform: spring._dragOffset.to(
                (_dragOffset) =>
                  `translate3d(${
                    isRtl ? _dragOffset * 100 : -(_dragOffset * 100)
                  }%, 0, 0)`,
              ),
            }}
            {...(useRole ? { role: 'list' } : {})}
          >
            {React.Children.map(children, (child) => (
              <Block
                extend={{
                  flex: '0 0 auto',
                  width: '100%',
                  ...createMediaQueryStyleBlocks(itemSpacingSet, (padding) => ({
                    padding: `0 ${padding / 2}px`,
                  })),
                }}
                onMouseDown={() => {
                  isDragging.current = false;
                }}
                onClickCapture={(event: React.MouseEvent) => {
                  if (isDragging.current) {
                    event.preventDefault();
                  }
                }}
                onDragStart={(event: React.MouseEvent) => {
                  event.preventDefault();
                }}
                /* The change has already been forced by the tab selection,
                     which means that we can only react to it.
                     As the DOM scrolls per default when 'tab' is pressed */
                onKeyDown={(e: KeyboardEvent) => {
                  if (e.key === 'Tab') {
                    const element = child as ReactElement;
                    carousel.setCurrent(Number(element.key));
                    carousel.emitEvent('tab-target-item');
                  }
                }}
                data-autoid="springCarouselPane:carouselItem"
                {...(useRole ? { role: 'listitem' } : {})}
              >
                {child}
              </Block>
            ))}
          </AnimatedDiv>
        </Flex>
      </Flex>
    </AnimatedDiv>
  );
};

/**
 * Method for calculating the current drag position and converts it to an index
 * value that can be used in the animation spring.
 */
const getDragPosition = ({
  dragMovePos,
  dragStartPos,
  itemWidthPx,
  minOffset,
  maxOffset,
}: {
  dragMovePos: number;
  dragStartPos: number;
  itemWidthPx: number;
  minOffset: number;
  maxOffset: number;
}) => {
  // When the offset goes below 0 or 100% of the pane we reduce the movement
  // speed of the pane with this multiplier.
  const DRAG_MODIFIER = 0.4;
  const val = dragStartPos - dragMovePos / itemWidthPx;

  if (val < minOffset) {
    return minOffset + (val - minOffset) * DRAG_MODIFIER;
  }

  if (val > maxOffset) {
    return maxOffset + (val - maxOffset) * DRAG_MODIFIER;
  }

  return val;
};

/**
 * Calculates all snap points for the pane and returns them in an array.
 */
const getSnapPoints = ({
  alignment,
  itemWidth,
  itemCount,
}: {
  alignment: Alignment;
  itemWidth: number;
  itemCount: number;
}): number[] => {
  let minOffset: number;
  let maxOffset: number;
  let baseOffset: number;

  // Check if all items are covering the pane.
  const coversPane = itemWidth * itemCount >= 1;

  // Remaining pane space outside 1 item width, in relation to the item width.
  const paneSpace = 1 / itemWidth - 1;

  // How many item widths that can cover the pane.
  const itemsPerPane = 1 / itemWidth;

  switch (alignment) {
    case 'left':
      minOffset = 0;
      maxOffset = itemCount - 1;
      baseOffset = 0;
      break;

    case 'center':
      minOffset = -(paneSpace / 2);
      maxOffset = -(paneSpace / 2) + itemCount - 1;
      baseOffset = -(paneSpace / 2);
      break;

    case 'right':
      minOffset = -paneSpace;
      maxOffset = -paneSpace + itemCount - 1;
      baseOffset = -paneSpace;
      break;

    case 'left-cover':
      minOffset = 0;
      maxOffset = Math.max(minOffset, itemCount - itemsPerPane);
      baseOffset = 0;
      break;

    case 'center-cover':
      minOffset = coversPane ? 0 : -(paneSpace / 2 - (itemCount - 1) / 2);
      maxOffset = coversPane
        ? itemCount - itemsPerPane
        : -(paneSpace / 2 - (itemCount - 1) / 2);
      baseOffset = -(paneSpace / 2);
      break;

    case 'right-cover':
      minOffset = coversPane ? 0 : -paneSpace + itemCount - 1;
      maxOffset = -paneSpace + itemCount - 1;
      baseOffset = -paneSpace;
      break;

    default:
      minOffset = 0;
      maxOffset = itemCount - 1;
      baseOffset = 0;
      break;
  }

  // Calculation of the snap points.

  minOffset = parseFloat(minOffset.toFixed(10));
  maxOffset = parseFloat(maxOffset.toFixed(10));
  baseOffset = parseFloat(baseOffset.toFixed(10));

  let currentValue = baseOffset;
  const snapPoints: number[] = [minOffset];

  while (currentValue < maxOffset) {
    currentValue = Math.min(maxOffset, currentValue + 1);

    if (currentValue > minOffset) {
      snapPoints.push(currentValue);
    }
  }

  return snapPoints;
};

/**
 * Will return a calculated drop point for the pane based on drag offset, snap
 * points and dragging direction.
 */
const getDropPoint = ({
  dragOffset,
  snapPoints,
  direction,
}: {
  dragOffset: number;
  snapPoints: number[];
  direction: number;
}): number => {
  if (dragOffset < snapPoints[0]) {
    return 1;
  }

  if (dragOffset >= snapPoints[snapPoints.length - 1]) {
    return snapPoints.length;
  }

  for (let i = 0; i < snapPoints.length - 1; i++) {
    const low = snapPoints[i];
    const high = snapPoints[i + 1];

    if (dragOffset >= low && dragOffset < high) {
      if (direction < 0) {
        return i + 2;
      } else if (direction > 0) {
        return i + 1;
      } else {
        const mid = (high - low) / 2;

        return dragOffset - low < mid ? i + 1 : i + 2;
      }
    }
  }

  return 1;
};

/**
 * Calculates the dragging progress of the pane based on drag offset and the
 * snap points of the carousel.
 */
const getProgress = ({
  dragOffset,
  snapPoints,
}: {
  dragOffset: number;
  snapPoints: number[];
}): number => {
  const min = snapPoints[0];
  const max = snapPoints[snapPoints.length - 1];

  if (dragOffset < min) {
    return (dragOffset - min) / max;
  }

  if (dragOffset >= max) {
    return dragOffset / max;
  }

  for (let i = 0; i < snapPoints.length; i++) {
    const low = snapPoints[i];
    const high = snapPoints[i + 1];

    if (dragOffset >= low && dragOffset < high) {
      return (i + (dragOffset - low) / (high - low)) / (snapPoints.length - 1);
    }
  }

  return 1;
};
