import {
  ProgressiveResponsiveImage,
  type ResponsiveImageProps,
} from '@vcc-package/media';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import SlidingPaginationIndicator from '@vcc-www/sliding-pagination-indicator';
import {
  SpringCarouselPane,
  useSpringCarousel,
} from '@vcc-www/spring-carousel';
import type { AspectRatio } from '@vcc-www/utils/getAspectRatioCSS';
import type { Sources } from '@vcc-www/utils/getMediaSources';
import Video from '@vcc-www/video';
import { Track } from '@volvo-cars/tracking';
import React from 'react';
import styles from './Carousel.module.css';

export type MediaItemsProps = {
  body?: string;
  imageSources: ResponsiveImageProps['images'];
  videoSourcesMp4?: Sources;
};

type CarouselProps = {
  mediaItems: Array<MediaItemsProps>;
  aspectRatio: AspectRatio;
  isOverlayOpen: boolean;
  shouldAutoplay: boolean;
  openOverlayLabel: string;
  setHasInteracted: () => void;
  setIsOverlayOpen: (isOverlayOpen: boolean) => void;
  disableOverlay?: boolean;
};

const Carousel = ({
  mediaItems,
  aspectRatio,
  setHasInteracted,
  isOverlayOpen,
  shouldAutoplay,
  disableOverlay,
  setIsOverlayOpen,
  openOverlayLabel,
}: CarouselProps) => {
  const { current, setCurrent } = useSpringCarousel();
  const sharedTranslate = useSharedComponentsTranslate();
  const paginationIndicatorAriaLabel = sharedTranslate(
    'Galleries.slidingGallery.paginationIndicatorAriaLabel',
  );

  return (
    <div
      className={styles.container}
      style={
        {
          '--expand-icon': `url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExIDFIMTdNMTcgMVY3TTE3IDFMMTEgNyIgc3Ryb2tlPSJibGFjayIvPgo8cGF0aCBkPSJNNyAxN0wxIDE3TTEgMTdMMSAxMU0xIDE3TDcgMTEiIHN0cm9rZT0iYmxhY2siLz4KPC9zdmc+Cg==')`,
        } as React.CSSProperties
      }
    >
      <SpringCarouselPane
        itemSpacing={{
          default: 24,
          onlyS: 8,
        }}
      >
        {mediaItems.map(({ imageSources, videoSourcesMp4 }, i) => {
          const isCurrent = current - 1 === i;
          const hasVideo =
            videoSourcesMp4 && Object.values(videoSourcesMp4).some(Boolean);

          return (
            <div key={i} className={styles.assetWrapper}>
              {!disableOverlay && (
                <Track eventLabel="open overlay" eventAction="image|click">
                  <button
                    type="button"
                    className={styles.openOverlayButton}
                    onClick={() => {
                      setIsOverlayOpen(true);
                    }}
                    aria-label={openOverlayLabel}
                    aria-current={isCurrent}
                    disabled={!isCurrent}
                    data-color-mode="light"
                  />
                </Track>
              )}
              {hasVideo ? (
                <Video
                  aspectRatio={aspectRatio}
                  poster={{
                    default: imageSources.sm.src,
                    fromM: imageSources.md?.src,
                    fromL: imageSources.lg?.src,
                  }}
                  srcMp4={videoSourcesMp4}
                  loading="lazy"
                  shouldAutoplay={!isOverlayOpen && shouldAutoplay && isCurrent}
                  playPauseButtonTabIndex={isCurrent ? 0 : -1}
                  shouldPause={!isCurrent || isOverlayOpen}
                />
              ) : (
                <ProgressiveResponsiveImage
                  images={{
                    ...imageSources,
                    smAspectRatio: imageSources.smAspectRatio
                      ? imageSources.smAspectRatio
                      : '4:5',
                    mdAspectRatio: imageSources.mdAspectRatio
                      ? imageSources.mdAspectRatio
                      : '4:3',
                    lgAspectRatio: imageSources.lgAspectRatio
                      ? imageSources.lgAspectRatio
                      : '16:9',
                  }}
                  alt={imageSources.sm.alt}
                  loading="lazy"
                  sizes={{ lg: '70vw' }}
                  className="w-full h-full"
                />
              )}
            </div>
          );
        })}
      </SpringCarouselPane>
      {mediaItems?.length && (
        <SlidingPaginationIndicator
          numberOfItems={mediaItems.length}
          currentIndex={current - 1}
          className="mt-8 mx-auto"
          onClick={(i) => {
            setCurrent(i + 1);
            setHasInteracted();
          }}
          ariaLabel={paginationIndicatorAriaLabel}
        />
      )}
    </div>
  );
};

export default Carousel;
