import type { ProductListProps } from '@components/product-list/product-list.types';
import { ProductHandle } from '@utils/constants';
import type { SiteSlug } from '@volvo-cars/market-sites';
import { defaultMarketConfig, getMarketConfigs } from 'src/markets';

export function getRetailerNavigationUrl(
  siteSlug: SiteSlug,
  productHandle?: string,
): string {
  const marketConfig = getMarketConfigs(siteSlug);
  const defaultConfig = defaultMarketConfig(siteSlug);
  if (marketConfig.linkToRetailer.containToProducts && productHandle) {
    return marketConfig.linkToRetailer.containToProducts.includes(productHandle)
      ? marketConfig.linkToRetailer.link
      : defaultConfig.linkToRetailer.link;
  }

  return marketConfig.linkToRetailer.link;
}

export function getRetailerContent(
  siteSlug: SiteSlug,
  contentType: 'retailerTextContent' | 'retailerFulfillmentContent',
): string {
  return getMarketConfigs(siteSlug)[contentType];
}

export function getFilteredNodes(
  siteSlug: SiteSlug,
  productNodeList: ProductListProps['productNodeList'],
): ProductListProps['productNodeList'] {
  // Retrieve market configuration based on site slug
  const marketConfig = getMarketConfigs(siteSlug);

  // If productNodeList is null or undefined, return it as is
  if (!productNodeList) {
    return productNodeList;
  }

  const hasServicePlan = productNodeList.some(
    (product) => product.productType === 'service-plan',
  );

  // If there are excluded product types defined in marketConfig, filter them out
  if (
    marketConfig &&
    Array.isArray(marketConfig.excludedProductTypes) &&
    marketConfig.excludedProductTypes.filter(Boolean).length > 0
  ) {
    const filteredNodes = productNodeList.filter(
      (product) =>
        !marketConfig.excludedProductTypes.includes(product.productType) &&
        product.productType !== 'service-plan',
    );

    return filteredNodes;
  }

  if (hasServicePlan) {
    const filteredNodes =
      createProductsListWithServicePlanNode(productNodeList);
    return filteredNodes;
  }

  // If no excluded product types are defined, return the original product list minus service-plan products
  return productNodeList;
}

function createProductsListWithServicePlanNode(
  productNodeList: NonNullable<ProductListProps['productNodeList']>,
) {
  const filteredNodes = productNodeList.filter(
    (product) => product.productType !== 'service-plan',
  );

  const firstServicePlanProduct = productNodeList.find(
    (product) => product.productType === 'service-plan',
  );

  if (!firstServicePlanProduct) {
    return productNodeList;
  }

  const firstServicePlanProductFirstVariant =
    firstServicePlanProduct.variants.nodes[0];
  if (!firstServicePlanProductFirstVariant) {
    return productNodeList;
  }

  const servicePlanNode: NonNullable<
    ProductListProps['productNodeList']
  >[number] = {
    ...firstServicePlanProduct,
    handle: ProductHandle.servicePlan,
    variants: {
      nodes: [
        {
          ...firstServicePlanProductFirstVariant,

          content: {
            ...firstServicePlanProductFirstVariant?.content,
            displayName: {
              value: 'Service Plan',
              ...firstServicePlanProductFirstVariant?.content?.displayName,
            },
          },
        },
      ],
    },
  };

  const products = [...filteredNodes, servicePlanNode];

  return products;
}
