import { SlidingGallery as SharedSlidingGallery } from '@vcc-package/galleries';
import { useBreakpoints } from '@vcc-www/hooks';
import type { AspectRatio } from '@vcc-www/utils/getAspectRatioCSS';
import type { SlidingGalleryProps } from './sliding-gallery.module';

export function SlidingGalleryLayout({
  items,
  initialCurrent,
}: SlidingGalleryProps) {
  // eslint-disable-next-line vcc-www/use-breakpoints
  const { untilM } = useBreakpoints();
  const ratio: AspectRatio = untilM ? [4, 3] : [16, 9];
  return (
    <div
      className="w-full"
      data-bleed
      style={{ overflowX: 'visible', maxWidth: '100vw' }}
    >
      <SharedSlidingGallery
        mediaItems={items}
        shouldAutoplay
        aspectRatioOverride={ratio}
        initialCurrent={initialCurrent}
      />
    </div>
  );
}
