'use client';

import { SlidingGallery } from '@components/sliding-gallery/sliding-gallery.module';
import { FALLBACK_WORDMARK_IMAGE } from '@utils/constants';
import { MediaGallery } from '@vcc-package/galleries';
import type { MediaProps } from './media.types';

export function Media({
  containerAttributes,
  items,
  isModalOpen, // Send this prop when wrapped by a modal
  withHints,
}: MediaProps) {
  if (withHints) {
    return <SlidingGallery items={items} />;
  }

  return (
    <MediaGallery
      containerAttributes={containerAttributes}
      mediaItems={items}
      imageFallbackUrl={FALLBACK_WORDMARK_IMAGE}
      isModalOpen={isModalOpen}
    />
  );
}
