'use client';

import {
  errorCategories,
  reportDetailedErrorMessage,
} from '@utils/error-logging/error-logging-messages';
import {
  EmbeddableForms,
  type EmbeddedFormErrorType,
} from '@vcc-package/embeddable-forms';

type AccessoryRequestModalProps = {
  accessoryTitle?: string;
  formRolloutSettings: {
    campaignCode: string | undefined;
    clientId: string | undefined;
  };
};

export default function AccessoryRequestModal({
  accessoryTitle,
  formRolloutSettings,
}: AccessoryRequestModalProps) {
  const additionalInfo = {
    accessoryName: accessoryTitle || '',
    accessoryUrl: window.location.href,
  };

  return (
    <div className="lg:px-24">
      <EmbeddableForms
        additionalInfo={additionalInfo}
        consumerApp="storefront-web"
        formType="AccessoryRequest"
        OnError={(type: EmbeddedFormErrorType, error: Error) =>
          reportDetailedErrorMessage({
            culprit: 'AccessoryRequestModal',
            errorCategory: errorCategories.GENERAL,
            details: { message: `${type} | ${error.message}` },
          })
        }
        settings={{
          formParameters: {
            campaigncode: formRolloutSettings?.campaignCode,
          },
          clientId: formRolloutSettings?.clientId,
        }}
      />
    </div>
  );
}
