import { BASE_URL } from '@config';
import { ProductHandle } from '@utils/constants';
import { isValidSearchParamTriggerVal } from '@utils/helpers/search-param-valid-trigger-val';
import type { SiteSlug } from '@volvo-cars/market-sites';
import type { PageSearchParams } from 'src/types/page-search-params';

export type MarketConfig = {
  allowMonthlyInstallments: boolean;
  directSalesProducts: string[];
  disableGuestCheckout: string[];
  enableOrderPageFlow: boolean;
  enablePriceForProducts: string[];
  excludedProductTypes: string[];
  linkToRetailer: {
    link: string;
    containToProducts?: string[];
  };
  retailerFulfillmentContent: string;
  retailerTextContent: string;
};

type MarketsCollection = { [key: string]: MarketConfig };

export const defaultMarketConfig = (siteSlug: string): MarketConfig => ({
  allowMonthlyInstallments: true,
  directSalesProducts: ['pilot-assist', 'service-plan'],
  disableGuestCheckout: [''],
  enableOrderPageFlow: false,
  enablePriceForProducts: [
    ProductHandle.performanceMode,
    ProductHandle.pilotAssist,
  ],
  excludedProductTypes: [''],
  linkToRetailer: {
    link: `${BASE_URL}/${siteSlug}/dealers`,
  },
  retailerFulfillmentContent: 'App.DetailPage.availableAtRetailer',
  retailerTextContent: 'App.CTA.retailer',
});

const volvoWallboxes = [
  ProductHandle.volvoWallboxWithCable,
  ProductHandle.volvoWallboxWithSocket,
  ProductHandle.volvoWallboxWithSocketMid,
];

const markets: MarketsCollection = {
  se: {
    ...defaultMarketConfig('se'),
    linkToRetailer: {
      link: 'https://kgkladdbarafordon.se/volvo/',
      containToProducts: volvoWallboxes,
    },
    retailerTextContent: 'App.CTA.inquiry',
    retailerFulfillmentContent: 'App.DetailPage.availableAtPartner',
    excludedProductTypes: ['insurance'],
  },
  nl: {
    ...defaultMarketConfig('nl'),
    linkToRetailer: {
      link: 'https://ccforms.fillout.com/volvo-laadpaal-aanvraag',
      containToProducts: volvoWallboxes,
    },
  },
  uk: {
    ...defaultMarketConfig('uk'),
    disableGuestCheckout: [ProductHandle.performanceMode],
    allowMonthlyInstallments: false,
  },
};

export function getMarketConfigs(
  market: SiteSlug,
  searchParams?: PageSearchParams,
): MarketConfig {
  if (isValidSearchParamTriggerVal(searchParams?.useDefaultMarketConfig)) {
    return defaultMarketConfig(market);
  }
  return markets[market] || defaultMarketConfig(market);
}
