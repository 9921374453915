import { useInView } from '@vcc-www/hooks';
import { useCurrentMarketSite } from '@vcc-www/market-sites';
import { cssMerge } from '@volvo-cars/css/utils';
import React, { useRef } from 'react';
import { animated, useSpring } from 'react-spring';
import styles from './SlidingPaginationIndicator.module.css';

type SlidingPaginationIndicatorProps = {
  numberOfItems: number;
  currentIndex: number;
  className?: string;
  onClick?: (index: number) => void;
  ariaLabel: string;
};

const SlidingPaginationIndicator: React.FC<SlidingPaginationIndicatorProps> = ({
  numberOfItems,
  currentIndex,
  className,
  onClick,
  ariaLabel,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { languageDirection } = useCurrentMarketSite();
  const { current } = ref || {};
  const { clientWidth = 0, offsetLeft } = current || {};
  const itemWidth = clientWidth / numberOfItems || 0;
  const [inViewRef, inView] = useInView<HTMLButtonElement>();

  const isRtl = languageDirection === 'rtl';

  const animatedStyles = useSpring({
    from: isRtl
      ? { transform: `translateX(${clientWidth}px)` }
      : { transform: `translateX(${currentIndex}px)` },
    to: isRtl
      ? [
          {
            transform: `translateX(${0 - currentIndex * itemWidth}px)`,
          },
        ]
      : [{ transform: `translateX(${currentIndex * itemWidth}px)` }],
    config: { tension: 120 },
  });

  const handleClick = (e: React.MouseEvent) => {
    if (typeof offsetLeft === 'number' && onClick && inView) {
      const rect = e.currentTarget.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const targetIndex = isRtl
        ? numberOfItems - Math.ceil(x / itemWidth)
        : Math.floor(x / itemWidth);
      onClick(targetIndex);
    }
  };

  return (
    <button
      type="button"
      className={cssMerge('h-32 w-full', className)}
      onClick={(e) => handleClick(e)}
      ref={inViewRef}
      aria-label={ariaLabel}
    >
      <div
        ref={ref}
        className={`my-auto ${styles.progressIndicator}`}
        data-autoid="slidingGallery:progressIndicator"
      >
        <animated.div style={animatedStyles}>
          <div className={styles.activeItem} style={{ width: itemWidth }} />
        </animated.div>
      </div>
    </button>
  );
};

export default SlidingPaginationIndicator;
