import { ProgressiveBaseImage } from '@vcc-package/media';
import { cssJoin } from '@volvo-cars/css/utils';
import { Icon } from '@volvo-cars/react-icons';
import { useTracker } from '@volvo-cars/tracking';
import React, { useRef, useState, useEffect, useMemo } from 'react';
import { animated, useSpring } from 'react-spring';
import { MediaItemsProps } from './Carousel';
import styles from './SlidingGallery.module.css';

export type ThumbnailProps = {
  mediaItems: Array<MediaItemsProps>;
  refMediaItems: { [key: number]: HTMLElement };
  previousButtonLabel: string;
  nextButtonLabel: string;
  currentIndex: number;
  handleScrollIntoView: (i: number) => void;
};

const HEIGHT_OF_THUMB_CONTAINER = 372; // 6 items max to show
const HEIGHT_OF_THUMB = 62; // Height of thumb + spacing: 54 + 8

const Thumbnails = (props: ThumbnailProps) => {
  const {
    mediaItems,
    refMediaItems,
    previousButtonLabel,
    nextButtonLabel,
    currentIndex,
    handleScrollIntoView,
  } = props;
  const [activeItem, setActiveItem] = useState(currentIndex);
  const [isUpVisible, toggleUpArrow] = useState(false);
  const [isDownVisible, toggleDownArrow] = useState(true);
  const thumbnailRef = useRef(null);
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);

  const tracker = useTracker();

  const displayNone = useMemo(() => ({ display: 'none' }), []);
  const displayFlex = useMemo(() => ({ display: 'flex' }), []);
  const [upArrowProps, setUpArrow] = useSpring(() => displayNone);
  const [downArrowProps, setDownArrow] = useSpring(() => displayFlex);
  const [translateProps, setTranslateY] = useSpring(() => ({ y: 0 }));
  const totalContainerHeight = mediaItems.length * HEIGHT_OF_THUMB;
  useEffect(() => {
    setActiveItem(currentIndex);
  }, [currentIndex]);
  useEffect(() => {
    if (focusedIndex !== null && focusedIndex < 5) {
      setUpArrow.start(displayNone);
      toggleUpArrow(false);
      if (mediaItems.length > 5) {
        setDownArrow.start(displayFlex);
        toggleDownArrow(true);
      }
      setTranslateY.start({ y: 0 });
    }

    if (
      (activeItem >= 5 && focusedIndex === null) ||
      (focusedIndex !== null && focusedIndex >= 5)
    ) {
      setUpArrow.start(displayFlex);
      toggleUpArrow(true);

      const currentThumbPos =
        ((focusedIndex !== null ? focusedIndex : activeItem) + 1) *
        HEIGHT_OF_THUMB;
      const isLast = currentThumbPos === totalContainerHeight;
      const hideDownArrow =
        totalContainerHeight - currentThumbPos === HEIGHT_OF_THUMB || isLast;

      if (hideDownArrow) {
        setDownArrow.start(displayNone);
        toggleDownArrow(false);
      } else {
        setDownArrow.start(displayFlex);
        toggleDownArrow(true);
      }

      setTranslateY.start({
        y: -(
          currentThumbPos +
          (isLast ? 0 : HEIGHT_OF_THUMB) -
          HEIGHT_OF_THUMB_CONTAINER
        ),
      });
    }
  }, [
    activeItem,
    focusedIndex,
    displayFlex,
    displayNone,
    setDownArrow,
    setTranslateY,
    setUpArrow,
    totalContainerHeight,
    mediaItems,
  ]);

  const handleArrowClick = (isUpClick?: boolean) => {
    if (translateProps.y.isAnimating) {
      return;
    }
    const currentTransition = translateProps.y.get();
    const nextTransition =
      (isUpClick ? HEIGHT_OF_THUMB : -HEIGHT_OF_THUMB) + currentTransition;

    const hideArrows = isUpClick
      ? HEIGHT_OF_THUMB_CONTAINER + nextTransition >= HEIGHT_OF_THUMB_CONTAINER
      : totalContainerHeight - HEIGHT_OF_THUMB_CONTAINER ===
        nextTransition * -1;
    setTranslateY.start({ y: nextTransition });

    if (isUpClick) {
      downArrowProps.display.get() === 'none' &&
        setDownArrow.start(displayFlex) &&
        toggleDownArrow(true);
      hideArrows && setUpArrow.start(displayNone) && toggleUpArrow(false);
    } else {
      upArrowProps.display.get() === 'none' &&
        setUpArrow.start(displayFlex) &&
        toggleUpArrow(true);
      hideArrows && setDownArrow.start(displayNone) && toggleDownArrow(false);
      // Show up arrow when transition down occurs.
      nextTransition === -HEIGHT_OF_THUMB &&
        setUpArrow.start(displayFlex) &&
        toggleUpArrow(true);
    }
  };

  const handleThumbClick = (i: number, description?: string) => {
    if (refMediaItems?.[i]) {
      tracker.interaction({
        eventAction: 'image|click',
        eventLabel: `thumbnail | ${i}${description ? ` | ${description}` : ''}`,
      });
      setActiveItem(i);
      handleScrollIntoView(i);
    }
  };

  return (
    <div
      className="flex sticky top-32 overflow-hidden pl-16 pr-8"
      style={{ height: HEIGHT_OF_THUMB_CONTAINER }}
      data-color-mode="light"
    >
      {mediaItems.length > 6 && (
        <>
          <animated.div style={upArrowProps} aria-hidden={!isUpVisible}>
            <button
              type="button"
              className={`absolute ${styles.arrowWrapper} ${styles.thumbnail} z-navigation`}
              onClick={() => handleArrowClick(true)}
              aria-label={previousButtonLabel}
            >
              <div
                className={cssJoin(
                  'flex rounded-full bg-primary text-primary',
                  'justify-center items-center w-24 h-24 m-auto transition-colors',
                )}
              >
                <Icon icon="chevron-up" size={12} color="currentColor" />
              </div>
            </button>
          </animated.div>
          <animated.div style={downArrowProps} aria-hidden={!isDownVisible}>
            <button
              type="button"
              className={`absolute ${styles.arrowWrapper} ${styles.thumbnail} z-navigation bottom-8`}
              onClick={() => handleArrowClick()}
              aria-label={nextButtonLabel}
            >
              <div
                className={cssJoin(
                  'flex rounded-full bg-primary text-primary',
                  'justify-center items-center w-24 h-24 m-auto transition-colors',
                )}
              >
                <Icon icon="chevron-down" size={12} color="currentColor" />
              </div>
            </button>
          </animated.div>
        </>
      )}
      <animated.div
        ref={thumbnailRef}
        style={translateProps}
        data-testid="thumbsWrapper"
      >
        {mediaItems.map(
          ({ imageSources, body }, i) =>
            imageSources.md?.src && (
              <button
                key={i}
                type="button"
                className="mb-8 block"
                onFocus={() => {
                  setFocusedIndex(i);
                }}
                onBlur={() => {
                  setFocusedIndex(null);
                }}
                onClick={() => handleThumbClick(i, body)}
              >
                <ProgressiveBaseImage
                  width={72}
                  height={54}
                  src={imageSources.md.src}
                  alt={imageSources.md.alt}
                  className={`object-cover rounded-sm border-2 ${styles.thumbnail} ${styles.thumbnailImage}`}
                  sizes="72px"
                  aria-selected={i === activeItem}
                />
              </button>
            ),
        )}
      </animated.div>
    </div>
  );
};

export default Thumbnails;
